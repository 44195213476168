var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"main-container",attrs:{"fluid":""}},[_c('link',{attrs:{"rel":"stylesheet","href":"../../node_modules/@fortawesome/fontawesome-free/css/all.css"}}),_c('nav-bar-pref'),_c('b-container',{staticClass:"banner-container"},[_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel-1","interval":20000,"controls":"","indicators":"","background":"#ababab"},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slideBanner),callback:function ($$v) {_vm.slideBanner=$$v},expression:"slideBanner"}},[_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{attrs:{"width":"100%","src":require(".././assets/sem-marca-concurso-publico---banner.gif")},on:{"click":function($event){return _vm.openUrl(
                'https://diribas.s3.sa-east-1.amazonaws.com/747+-+SUPLEMENTO.pdf')}}})]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{attrs:{"width":"100%","src":require(".././assets/Taxa-do-Lixo.gif")},on:{"click":function($event){return _vm.openUrl(
                'http://45.174.220.245:8056/servicosweb/home.jsf'
              )}}})]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{attrs:{"width":"100%","src":require(".././assets/campanha-dengue---banner.gif")}})]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{attrs:{"width":"100%","src":require(".././assets/banner-campanha-gripe.gif")},on:{"click":function($event){return _vm.openUrl(
                'https://www.ribasdoriopardo.ms.gov.br/publicacoes/3859/-prefeitura-de-ribas-do-rio-pardo-inicia-campanha-de-vacinacao-contra-gripe-2024')}}})]},proxy:true}])})],1)],1),_c('b-row',{staticClass:"services-row"},[_c('b-container',{staticClass:"services-container",attrs:{"fluid":""}},[_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.services),function(service){return _c('div',{key:service.name,staticClass:"carousel-item"},[_c('div',{staticClass:"wrapper"},[(!service.blank)?_c('router-link',{attrs:{"to":service.route}},[_c('i',{class:service.icon}),_c('p',[_vm._v(_vm._s(service.name))]),_c('small',[_vm._v("Clique e acesse")])]):_c('a',{attrs:{"href":service.route,"target":"_blank"}},[_c('i',{class:service.icon}),_c('p',[_vm._v(_vm._s(service.name))]),_c('small',[_vm._v("Clique e acesse")])])],1)])}),0)],1)],1),_c('b-row',{staticClass:"middle-row"},[_c('b-col',{staticClass:"carousel-container",attrs:{"md":"12","lg":"5","sm":"12"}},[_c('p',{staticClass:"label"},[_vm._v("DESTAQUES")]),(_vm.featuredNews.length <= 0)?_c('div',{staticStyle:{"min-height":"450px"}}):_vm._e(),_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel-1","interval":4000,"controls":"","indicators":"","background":"#ababab","img-width":"1024","img-height":"200"},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.featuredNews),function(featured){return _c('b-carousel-slide',{key:featured.id,attrs:{"caption":featured.title}},[_c('img',{staticClass:"d-block img-fluid w-100",attrs:{"slot":"img","width":"480","height":"480","src":featured.PublicationImgs[0] && featured.PublicationImgs[0].url
                ? featured.PublicationImgs[0].url
                : require('.././assets/noimg.png'),"alt":"image slot"},on:{"click":function($event){return _vm.goTo('publication-view', featured.id, featured.title)}},slot:"img"})])}),1)],1),_c('b-col',{staticClass:"news-container",attrs:{"md":"12","sm":"12","lg":"5"}},[_c('p',{staticClass:"label"},[_vm._v("NOTÍCIAS")]),_c('b-card-group',_vm._l((_vm.news),function(publication){return _c('b-card',{key:publication.id,staticClass:"hvr-shadow",attrs:{"title":publication.title.length <= 75
              ? publication.title
              : publication.title.substring(0, 75) + '...',"img-src":publication.PublicationImgs[0] &&
            publication.PublicationImgs[0].url
              ? publication.PublicationImgs[0].url
              : require('.././assets/noimg.png'),"img-top":"","tag":"article"},on:{"click":function($event){return _vm.goTo('publication-view', publication.id, publication.title)}}},[_c('div',{staticClass:"tag-wrapper"},[_c('hr',{staticClass:"separator"}),(publication.tags && publication.tags.length > 0)?_c('small',{staticClass:"tag"},[_vm._v(_vm._s(publication.tags[0].name))]):_vm._e()]),_c('b-card-text',[_c('span',[_vm._v("Saiba mais")])])],1)}),1)],1),_c('b-col',{staticClass:"news-list-container",attrs:{"lg":"2","md":"12","sm":"12"}},[_c('p',{staticClass:"label"}),_c('ul',{staticClass:"news-list-ul"},_vm._l((_vm.newsList),function(publication){return _c('li',{key:publication.id},[_c('b-card-group',{staticClass:"news-list-group",attrs:{"title":publication.title}},[_c('b-card',{attrs:{"img-src":publication.PublicationImgs[0] &&
                publication.PublicationImgs[0].url
                  ? publication.PublicationImgs[0].url
                  : require('.././assets/noimg.png'),"img-left":""},on:{"click":function($event){return _vm.goTo('publication-view', publication.id, publication.title)}}},[(publication.tags && publication.tags.length > 0)?_c('small',{staticClass:"news-list-tag"},[_vm._v(_vm._s(publication.tags[0].name))]):_vm._e(),_c('span',{staticClass:"news-list-title"},[_vm._v(" "+_vm._s(_vm.formatTitle(publication.title))+" ")])])],1),_c('hr',{staticClass:"news-list-separator"})],1)}),0),_c('div',[_c('p',{staticClass:"label all"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'list',
              params: { tag: 'todas', type: _vm.typeId, page: 0, size: 10 },
            }}},[_vm._v("VER TODAS")]),_c('i',{staticClass:"fas fa-angle-right"})],1)])])],1),_c('footer-pref')],1)}
var staticRenderFns = []

export { render, staticRenderFns }