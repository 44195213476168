<template>
  <div id="maintenance">
    <nav-bar-pref></nav-bar-pref>
    <b-container class="maintenance-container">
      <b-col md="2" v-if="!isMobile" class="side-menu">
        <a
          href="http://45.174.220.254:8079/transparencia"
          target="_blank"
          ><p class="border-bottom">PORTAL DA TRANSPARÊNCIA</p></a
        >
        <a
          href="http://portal.issqn.net/tecnosis/Portal.html#versao=30"
          target="_blank"
          ><p class="border-bottom">EMISSÃO DE NFE</p></a
        >
        <a
          href="http://portal.issqn.net/tecnosis/Portal.html#versao=30"
          target="_blank"
          ><p class="border-bottom">EMISSÃO DE CND</p></a
        >
        <router-link to="/diribas"
          ><p class="border-bottom">DIÁRIO OFICIAL</p></router-link
        >
        <a href="https://itr.vast.com.br/admin.php" target="_blank"
          ><p class="border-bottom">ITR</p></a
        >
        <router-link to="/editais"
          ><p class="border-bottom">LICITAÇÕES E EDITAIS</p></router-link
        >
        <router-link to="/ouvidoria"
          ><p class="border-bottom">OUVIDORIA</p></router-link
        >
        <a
          href="http://45.174.220.242:8085/solicitacao/open.do?action=open&sys=FOR"
          target="_blank"
          ><p class="border-bottom">FORNECIMENTO MUNICIPAL</p></a
        >
        <a
          href="http://45.174.220.242:8085/requisicao-compra/#/login"
          target="_blank"
          ><p class="border-bottom">REQUISIÇÃO MUNICIPAL</p></a
        >
        <a
          href="http://45.174.220.242:8056/sipweb/trabalhador/login/login.xhtml"
          target="_blank"
          ><p class="border-bottom">HOLERITE WEB</p></a
        >
        <router-link to="/terra-nua"
          ><p class="border-bottom">VALOR DA TERRA NUA / ITBI</p></router-link
        >
      </b-col>
      <b-col md="8" class="warning-img">
        <img src="@/assets/ribas-logo-2021.png" />
        <h2>
          Você pode acessar os portais de serviços através dos links dispostos
          na lateral e na parte superior do site.
        </h2>
      </b-col>
    </b-container>

    <footer-pref class="footer-maintenance"></footer-pref>
  </div>
</template>

<script>
import FooterPref from "../components/FooterPref";
import NavBarPref from "../components/NavBarPref";
import { isMobile } from "mobile-device-detect";

export default {
  name: "Maintenance",
  data() {
    return {};
  },
  computed: {
    isMobile() {
      return isMobile;
    },
  },

  components: {
    FooterPref,
    NavBarPref,
  },

  mounted() {},
};
</script>

<style lang="scss">
#maintenance {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .maintenance-container {
    background-color: white;
    display: flex;
    max-width: 90%;
    @media (max-width: 1200px) {
      max-width: 98%;
      font-size: 13px;
    }
    .side-menu {
      background-color: #204991;
      height: 600px;
      margin: 1% auto;
      display: grid;
      align-content: space-around;
      P {
        color: white;
        font-weight: bold;
      }
      p:hover {
        background-color: #3265bf;
        transition: 0.3s;
        border-radius: 5px;
      }
    }
    .warning-img {
      padding-top: 10px;
      img {
        width: 630px;
        @media (max-width: 1280px) {
          width: 430px;
        }
        @media (max-width: 990px) {
          width: 250px;
        }
      }
      h1,
      h2 {
        font-family: Raleway;
        font-weight: bold;
        color: black;
        text-align: center;
        margin-top: 15px;
        @media (max-width: 940px) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>