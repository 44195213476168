<template>
    <div>
        <footer class="footer">
      <link
        rel="stylesheet"
        href="../../node_modules/@fortawesome/fontawesome-free/css/all.css"
      />
      <b-row>
        <b-col md="4" class="logo-bottom">
          <hr class="separator" />
          <img src="@/assets/ribas-logo-2021.png" />
          <small class="disclaimer">
            © Prefeitura Municipal de Ribas do Rio Pardo, 2021-2024. Todos os
            direitos reservados.
          </small>
        </b-col>
        <b-col md="4" class="info">
          <p>Atendimento ao Cidadão/Contribuinte:</p>
          <small class="days">
            Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h <br />Tel. 0800-808-1175
            <br />
            <small>
              Rua Conceição do Rio Pardo, 1725 - Centro - Ribas do Rio Pardo -
              MS
            </small>
          </small>
        </b-col>
        <b-col md="4" v-if="!isMobile" class="social">
          <hr class="separator" />
          <!-- <p>Siga-nos nas Redes Sociais:</p>
          <div class="icons">
            <a
              href="https://www.facebook.com/ribasdoriopardooficial"
              target="_blank"
              ><i class="fab fa-facebook fa-2x"></i
            ></a>
            <a
             href="https://www.instagram.com/prefribas/"
             target="_blank"
             ><i class="fab fa-instagram fa-2x"></i></a>
             <a
             href="https://www.youtube.com/channel/UC-UP9KvWM3nsFmUHQvkSHNA"
             target="_blank"
             ><i class="fab fa-youtube fa-2x"></i></a>
            
          </div> -->
        </b-col>
      </b-row>
    </footer>
    </div>
</template>
<script>
import { isMobile } from "mobile-device-detect";
export default {
    name: "FooterPref",
    computed: {
    isMobile() {
      return isMobile;
    },
  }
}
</script>
<style lang="scss">
footer {
  background: #42A5F5;
  color: white;
  padding: 0 20px;
}
.footer {
  background-color: #204991;
  color: white;
  min-height: 10%;
  .logo-bottom {
    display: grid;
    position: relative;
    padding-right: unset;
    @media (max-width: 767px) {
      display: inline-flex;
    }
    .separator {
      border: 1px solid #efd02f;
      border-radius: 5px;
      margin: unset;
      margin-top: 2px;
    }
    img {
      position: relative;
      display: flex;
      height: 60px;
      padding-left: 1%;
      @media (max-width: 768px) {
        height: 40px;
        padding-left: unset;
      }
    }
  }
  .disclaimer {
    display: flex;
    width: 100%;
    bottom: 0;
    font-family: Roboto;
    padding-left: 4%;
    font-size: 12px;
    @media (max-width: 767px) {
      padding-left: 5px;
      width: 55%;
    }
  }
  .info {
    display: grid;
    padding-left: unset;
    p {
      font-family: Raleway;
      font-weight: bold;
      width: 100%;
      margin: 0;
    }
    small {
      width: 100%;
    }
    .days {
      font-family: Roboto;
      bottom: 0;
    }
  }
  .social {
    .separator {
      border: 1px solid #efd02f;
      border-radius: 5px;
      margin: unset;
      margin-top: 2px;
    }
    .icons {
      padding-top: 1%;
      a {
        color: inherit;
      }
    }
    p {
      margin-bottom: unset;
      font-family: Raleway;
      font-weight: bold;
      padding-top: 1%;
    }
    i {
      padding: 5px;
    }
  }
  }
</style>